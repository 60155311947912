@font-face {
  font-family: "TT Talent";
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/TT_Talent_Regular.woff2") format("woff2"),url("../fonts/TT_Talent_Regular.woff") format("woff")
}

@font-face {
  font-family: "TT Talent";
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/TT_Talent_Medium.woff2") format("woff2"),url("../fonts/TT_Talent_Medium.woff") format("woff")
}

@font-face {
  font-family: "TT Talent";
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/TT_Talent_DemiBold.woff2") format("woff2"),url("../fonts/TT_Talent_DemiBold.woff") format("woff")
}

@font-face {
  font-family: "TT Talent";
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/TT_Talent_Bold.woff2") format("woff2"),url("../fonts/TT_Talent_Bold.woff") format("woff")
}

@font-face {
  font-family: "TT Talent";
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/TT_Talent_Italic.woff2") format("woff2"),url("../fonts/TT_Talent_Italic.woff") format("woff")
}

@font-face {
  font-family: "TT Talent";
  font-display: swap;
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/TT_Talent_DemiBold_Italic.woff2") format("woff2"),url("../fonts/TT_Talent_DemiBold_Italic.woff") format("woff")
}

@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Graphik-Regular'), url('../fonts/GraphikRegular.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Graphik-RegularItalic'), url('../fonts/GraphikRegularItalic.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 250;
    font-display: swap;
    src: local('Graphik-Thin'), url('../fonts/GraphikThin.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 250;
    font-display: swap;
    src: local('Graphik-ThinItalic'), url('../fonts/GraphikThinItalic.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 275;
    font-display: swap;
    src: local('Graphik-Extralight'), url('../fonts/GraphikExtralight.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 275;
    font-display: swap;
    src: local('Graphik-ExtralightItalic'), url('../fonts/GraphikExtralightItalic.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Graphik-Light'), url('../fonts/GraphikLight.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Graphik-LightItalic'), url('../fonts/GraphikLightItalic.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Graphik-Medium'), url('../fonts/GraphikMedium.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local('Graphik-MediumItalic'), url('../fonts/GraphikMediumItalic.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Graphik-Semibold'), url('../fonts/GraphikSemibold.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Graphik-SemiboldItalic'), url('../fonts/GraphikSemiboldItalic.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Graphik-Bold'), url('../fonts/GraphikBold.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Graphik-BoldItalic'), url('../fonts/GraphikBoldItalic.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Graphik-Black'), url('../fonts/GraphikBlack.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Graphik-BlackItalic'), url('../fonts/GraphikBlackItalic.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 950;
    font-display: swap;
    src: local('Graphik-Super'), url('../fonts/GraphikSuper.woff2') format('woff2');
}
@font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 950;
    font-display: swap;
    src: local('Graphik-SuperItalic'), url('../fonts/GraphikSuperItalic.woff2') format('woff2');
}
